// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-cases-index-tsx": () => import("./../../../src/pages/cases/index.tsx" /* webpackChunkName: "component---src-pages-cases-index-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-directions-index-tsx": () => import("./../../../src/pages/directions/index.tsx" /* webpackChunkName: "component---src-pages-directions-index-tsx" */),
  "component---src-pages-directions-pages-backend-index-tsx": () => import("./../../../src/pages/directions/pages/backend/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-backend-index-tsx" */),
  "component---src-pages-directions-pages-cdto-index-tsx": () => import("./../../../src/pages/directions/pages/cdto/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-cdto-index-tsx" */),
  "component---src-pages-directions-pages-cource-index-tsx": () => import("./../../../src/pages/directions/pages/cource/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-cource-index-tsx" */),
  "component---src-pages-directions-pages-crypto-index-tsx": () => import("./../../../src/pages/directions/pages/crypto/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-crypto-index-tsx" */),
  "component---src-pages-directions-pages-delivery-index-tsx": () => import("./../../../src/pages/directions/pages/delivery/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-delivery-index-tsx" */),
  "component---src-pages-directions-pages-design-index-tsx": () => import("./../../../src/pages/directions/pages/design/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-design-index-tsx" */),
  "component---src-pages-directions-pages-fintech-index-tsx": () => import("./../../../src/pages/directions/pages/fintech/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-fintech-index-tsx" */),
  "component---src-pages-directions-pages-iot-index-tsx": () => import("./../../../src/pages/directions/pages/iot/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-iot-index-tsx" */),
  "component---src-pages-directions-pages-kmm-index-tsx": () => import("./../../../src/pages/directions/pages/kmm/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-kmm-index-tsx" */),
  "component---src-pages-directions-pages-restocare-index-tsx": () => import("./../../../src/pages/directions/pages/restocare/index.tsx" /* webpackChunkName: "component---src-pages-directions-pages-restocare-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internship-index-tsx": () => import("./../../../src/pages/internship/index.tsx" /* webpackChunkName: "component---src-pages-internship-index-tsx" */),
  "component---src-pages-kmm-index-tsx": () => import("./../../../src/pages/kmm/index.tsx" /* webpackChunkName: "component---src-pages-kmm-index-tsx" */),
  "component---src-pages-order-index-tsx": () => import("./../../../src/pages/order/index.tsx" /* webpackChunkName: "component---src-pages-order-index-tsx" */),
  "component---src-pages-reviews-index-tsx": () => import("./../../../src/pages/reviews/index.tsx" /* webpackChunkName: "component---src-pages-reviews-index-tsx" */),
  "component---src-pages-speeches-index-tsx": () => import("./../../../src/pages/speeches/index.tsx" /* webpackChunkName: "component---src-pages-speeches-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-vacancies-index-tsx": () => import("./../../../src/pages/vacancies/index.tsx" /* webpackChunkName: "component---src-pages-vacancies-index-tsx" */),
  "component---src-templates-blog-page-index-tsx": () => import("./../../../src/templates/BlogPage/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-index-tsx" */),
  "component---src-templates-case-page-index-tsx": () => import("./../../../src/templates/CasePage/index.tsx" /* webpackChunkName: "component---src-templates-case-page-index-tsx" */),
  "component---src-templates-post-page-index-tsx": () => import("./../../../src/templates/PostPage/index.tsx" /* webpackChunkName: "component---src-templates-post-page-index-tsx" */),
  "component---src-templates-vacancy-page-index-tsx": () => import("./../../../src/templates/VacancyPage/index.tsx" /* webpackChunkName: "component---src-templates-vacancy-page-index-tsx" */)
}

